.boxHeaderInfo {
    display: flex;
}
.boxHeaderText {    
    padding-left: 30px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.boxHeaderbanner {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: flex-start;
}
.textInfoApp {
    gap:24px;   
    padding: 24px;
}
.textVersionApp{
    gap:24px;
    padding: 24px 24px 0 24px;
}
.actionApp{
    padding: 0 24px 24px 24px;
}