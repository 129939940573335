:root {
  --layout-color: #f4f3f6;
  --vertical-color: #f4f3f6;
  --horizontal-color: #f4f3f6;
  --content-color: #f4f3f6;
}
.bodyLayout {
  background-color: var(--layout-color);
  height: 100vh;
}
.vertical {  
  background-color: var(--vertical-color);
  /* overflow-y: auto; */
}
.horizontal {
  background-color: var(--horizontal-color);
}
.bodyContent {
  background-color: var(--content-color);
  height: calc(100vh - 0px);
  width: 100%;
}



