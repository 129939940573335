:root{
    --footer-height: 128px;
    --menu-bg-color: #ffffff;
}
.menu {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}
.container {
    background-color: var(--menu-bg-color);
    z-index: 4;   
    min-height: calc(100vh - var(--footer-height));
    width: 100%;
    padding-top: 37px;
    max-height: calc(100vh - var(--footer-height));
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #666666;
    }   
}    
.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;
    background-color:  var(--menu-bg-color);
}
.favorites{
    padding: 24px 0 6px 0;
}
.boxFavorite{
    display: flex;
    align-items: center;    
    flex-direction: row;
    gap: 4px;
}
.title{
    display: flex;
    padding: 0 32px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    color:#A3A3A3
    
}
.containerApps{
    width: 100%;
    overflow-y: auto;
}
.body{
    padding-top: 24px;
    width: 100%;
    max-height: calc(100vh - var(--footer-height));
}
.listMenu{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.listMenuItemSub,
.listMenuItem {
    font-size: 14px;
    color:#292929;
    font-weight: 400;
    line-height: 16px;
    height: 40px;
    margin: 0 16px 0 16px;
    cursor: pointer;
}
.listMenuItem {
    border-radius: 4px;
}
.listMenuItemSub {   
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.listMenuItemSubActive {
   margin: 0 0 0 16px !important;
}
.listmenuItemBox {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 4px 12px;
}
.listmenuItemBox:hover {
    background-color: #F3F8FC; 
}
.text {
    display:flex;
    align-items: center;
    flex:1;
    gap:6px;
}
.icon,
.iconActive { 
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.iconActive {
    margin-right: 16px;
}
.boxIconOpen {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.activeMenu {
    background-color: #F3F8FC;
}
.footer{
    background-color: var(--menu-bg-color);
    width: 100%;
    height: var(--footer-height);
    box-shadow:  0px 1px 2px rgba(0, 0, 0, 0.06);
}
.accountVersion{
    padding: 24px 16px 0 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
/*
notebook
*/
@media (min-width: 768px) and (max-width: 1366px) {
    .listMenuItemSub,
    .listMenuItem {
        margin: 0 10px 0 10px;
        
    }
    .listmenuItemBox {
        border-radius: 4px;
        padding: 0px 11px 0px 11px;
    }
    .listMenuItemSubActive { 
        margin: 0 0 0 10px !important;
    }
}