.iconError {
  background-color: #cce1ff;
  width: 40px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.btnStatusFile {
  display: flex;
  gap: 6px;
}
.titleMsg {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  color: #004099;
  padding-top: 16px;
}
.subTitleMsg {
  font-size: 13px;
  color: #292929;
  font-weight: 400;
  padding-top: 8px;
}

.processBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  gap: 20px;
  flex-direction: column;
}
