.custom-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #c4c4c4 #f1f1f1; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #666666;
}