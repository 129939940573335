.contentIntegrations {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.textBoxIntegration {
  background-color: #e8ebf2;
  z-index: 1;
  padding: 45px 55px;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 0px rgba(39, 35, 46, 0.1),
    0px 2px 4px 0px rgba(39, 35, 46, 0.1),
    0px 8px 8px 0px rgba(39, 35, 46, 0.09),
    0px 18px 11px 0px rgba(39, 35, 46, 0.05),
    0px 31px 12px 0px rgba(39, 35, 46, 0.01),
    0px 49px 14px 0px rgba(39, 35, 46, 0);
}
.cards {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  padding-bottom: 225px;
  
}
.cards::-webkit-scrollbar {
  background-color: #ddd;
  width: 6px;
  height: 6px;
}
.cards::-webkit-scrollbar-track {
  width: 4px;
}
.cards::-webkit-scrollbar-thumb {
  background: #a1a1aa;
  border-radius: 24px;
}
.cards thead {
  position: sticky;
  top: 0;
  z-index: 2;
}
.cardBox {
  width: 336px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  background-color: #ffffff;
}
.cardHeader {
  padding: 27px 27px 0 27px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.cardBody {
  padding: 0px 27px;
  height: 100px;
  display: flex;
  align-items: center;
}
.cardFooter {
  padding: 14px 22px;
  align-items: center;
  display: flex;
  justify-content:flex-end;
}
.cardFooterButton {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #1155BB;
  gap: 5px;
  padding: 0px 8px;
}

.toolsFooterButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: #1155BB;
  gap: 5px;
  padding: 0px 8px;
}

